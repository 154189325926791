// hooks/useUserData.ts
import { useState, useEffect } from 'react';
import { getUserFromFirestore } from '../services/firebase/userService';
import { useUser } from '../Context/UserContext';

interface FormData {
    [key: string]: any;
}

interface UseUserDataResult {
    displayUser: any; // Adjust this to a specific type if available
    user: any;        // Adjust this to a specific type if available
}

interface Language {
    lang: string;
    level: string;
}

export const initialFormData = (userData: any): FormData => ({
    lastName: userData?.lastName || '',
    firstName: userData?.firstName || '',
    password: '',
    languages: userData?.languages || [],
    aspirations: userData?.aspirations || '',
    gender: userData?.gender || '',
    email: userData?.email || '',
    emailAddresses: [
        { email: userData?.email || '', description: "ログイン用のメールアドレス" },
        { email: userData?.email2 || "", description: "サブメールアドレス1" },
        { email: userData?.email3 || "", description: "サブメールアドレス2" }
    ],
    phoneNumber: userData?.phoneNumber || '',
    grade: userData?.grade || '',
    schoolName: userData?.schoolName || '',
    postalCode: userData?.postalCode || '',
    prefecture: userData?.prefecture || '',
    city: userData?.city || '',
    streetAddress: userData?.streetAddress || '',
    emailNotification: userData?.emailNotification || '',
    interestsEmploymentTags: userData?.interestsEmploymentTags || [],
    interestsEducationTags: userData?.interestsEducationTags || [],
});

export const useUserData = (userId: string): UseUserDataResult => {
    const [displayUser, setDisplayUser] = useState<any>(null);
    const { user } = useUser();

    useEffect(() => {
        const fetchUserData = async () => {
            if (userId === user?.uid) {
                setDisplayUser(user);
            } else {
                try {
                    const fetchedUser = await getUserFromFirestore(userId);
                    setDisplayUser(fetchedUser);
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
        fetchUserData();
    }, [userId, user]);

    return { displayUser, user };
};

export const useProfileFormData = (userId: string | undefined, user: any) => {
    const [formData, setFormData] = useState<FormData>(initialFormData(user) || {});

    useEffect(() => {
        if (userId) {
            const fetchUserData = async () => {
                if (userId === user?.uid) {
                    setFormData(initialFormData(user));
                } else {
                    const userData = await getUserFromFirestore(userId);
                    if (userData) {
                        setFormData(initialFormData(userData));
                    }
                }
            };
            fetchUserData();
        }
    }, [userId, user]);

    return {
        formData,
        setFormData,
    };
};


export const useDialogs = () => {
    const [dialogsOpen, setDialogsOpen] = useState({
        updatePassword: false,
        updateEmail: false,
        addEmail: false,
    });

    const toggleModal = (modalName: keyof typeof dialogsOpen, isOpen: boolean) => {
        setDialogsOpen((prev) => ({ ...prev, [modalName]: isOpen }));
    };

    return {
        dialogsOpen,
        toggleModal,
    };
};

export const useFormHandlers = (formData: FormData, setFormData: React.Dispatch<React.SetStateAction<FormData>>) => {
    const handleInputChange = (fieldName: string, newValue: any, index: number = 0) => {
        if (fieldName === 'emailAddresses') {
            const updatedEmailAddresses = [...formData.emailAddresses];
            updatedEmailAddresses[index].email = newValue;
            setFormData({ ...formData, emailAddresses: updatedEmailAddresses });
        } else {
            setFormData({ ...formData, [fieldName]: newValue });
        }
    };

    const handleAutocompleteChange = (fieldName: string, newValue: any[]) => {
        const values = newValue.map((item) => item.value);
        handleInputChange(fieldName, values);
    };

    const handleLanguageChange = (index: number, field: keyof Language, value: string) => {
        const newLanguages = [...formData.languages];
        newLanguages[index] = { ...newLanguages[index], [field]: value };
        setFormData({ ...formData, languages: newLanguages });
    };

    const addLanguageField = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            languages: [...(prevFormData.languages || []), { lang: '', level: '' }],
        }));
    };

    return {
        handleInputChange,
        handleAutocompleteChange,
        handleLanguageChange,
        addLanguageField,
    };
};

