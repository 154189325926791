import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { getChatsQuery, getCompanyDetails } from '../services/firebase/chatService';
import { User } from '../types/User';
import { getUserFromFirestore } from '../services/firebase/userService';

interface ChatRoom {
    id: string;
    title: string;
    detail: string;
    participants: string[];
    avatarSrc: string;
    companyId: string;
    chatTag: string;
    lastMessage: string;
    companyDisplaySrc: string;
    startUid: string;
    // その他必要なプロパティ
}

export const useArchivedChats = (user: User, isArchived: boolean = false) => {
    const [chatRooms, setChatRooms] = useState<ChatRoom[]>([]);
    const [showArchivedChats, setShowArchivedChats] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        const q = getChatsQuery(user, isArchived);
        if (!q) return;

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const rooms: ChatRoom[] = [];

            for (const roomDoc of querySnapshot.docs) {
                const roomData = roomDoc.data();
                let title = '';
                let detail = '';
                let avatarSrcUrl = '';
                let startUid = '';
                if (user?.userType !== 'company' && roomData.companyId) {
                    const companyData = await getCompanyDetails(roomData.companyId);
                    if (companyData) {
                        title = companyData.name;
                        detail = companyData.companyDetail;
                        avatarSrcUrl = companyData?.iconUrl || '';
                        startUid = roomData.companyId;
                    }
                } else if (user?.userType === 'company') {
                    const userData = await getUserFromFirestore(roomData.startedBy.userId);
                    title = `${userData?.lastName} ${userData?.firstName}`;
                    avatarSrcUrl = userData?.userIconUrl || '';
                    startUid = userData?.uid;
                }

                const { avatarSrc, ...roomDataWithoutAvatar } = roomData;

                rooms.push({
                    id: roomDoc.id,
                    chatTag: roomData.chatTag,
                    avatarSrc: avatarSrcUrl,
                    ...roomDataWithoutAvatar,
                    title,
                    detail,
                    participants: [],
                    lastMessage: roomData.lastMessage,
                    companyId: roomData.companyId,
                    companyDisplaySrc: '',
                    startUid: startUid
                });
            }

            setChatRooms(rooms);
            setShowArchivedChats(isArchived);
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, [user, isArchived]);

    return { chatRooms, showArchivedChats, isLoading };
};
