import { doc, setDoc, getDoc, serverTimestamp, updateDoc, addDoc, collection, where, getDocs, query, orderBy } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export interface UserData {
    email: string;
    phoneNumber?: string;
    lastName?: string;
    firstName?: string;
    birthDate?: Timestamp | string;
    gender?: string;
    userType?: string;
    age?: number;
    handleTeacherReadToggle?: boolean;
    [key: string]: any;  // Allows additional fields if necessary
}

// Create a new user in Firestore
export const createUserInFirestore = async (userId: string, userData: UserData): Promise<void> => {
    const userDocRef = doc(firestore, 'users', userId);
    try {
        await setDoc(userDocRef, {
            ...userData,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            isDeleted: false,
        });
    } catch (error: any) {
        throw new Error(`Failed to create user: ${error.message}`);
    }
};

// Fetch a user from Firestore by userId
export const getUserFromFirestore = async (userId: string): Promise<UserData | null> => {
    const userDocRef = doc(firestore, 'users', userId);
    try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
            return userDoc.data() as UserData;
        }
        return null;
    } catch (error: any) {
        throw new Error(`Failed to fetch user: ${error.message}`);
    }
};

// Update an existing user's information in Firestore
export const updateUserInFirestore = async (userId: string, updatedData: Partial<UserData>): Promise<void> => {
    const userDocRef = doc(firestore, 'users', userId);
    try {
        await setDoc(userDocRef, {
            ...updatedData,
            updatedAt: Timestamp.now(),
        }, { merge: true });
    } catch (error: any) {
        throw new Error(`Failed to update user: ${error.message}`);
    }
};

export const fetchUserHistory = async (userId: string) => {
    const historyRef = collection(firestore, "users", userId, "history");
    const q = query(historyRef, where("isDisabled", "==", false), orderBy("endedAt", "desc"));
    const querySnapshot = await getDocs(q);
    const historyItems = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
    }));
    return historyItems;
};

export const saveCertificationOrAward = async (data: any, userDocId: string, category: string) => {
    const docRef = doc(firestore, "users", userDocId);
    const userDoc = await getDoc(docRef);
    let itemsArray: any[] = [];

    if (userDoc.exists()) {
        const userData = userDoc.data();
        itemsArray = userData[category] ? [...userData[category]] : [];
    }

    if (data.id) {
        const index = itemsArray.findIndex(item => item.id === data.id);
        if (index !== -1) {
            itemsArray[index] = { ...data };
        }
    } else {
        const newData = { ...data, id: uuidv4(), addedAt: new Date().toISOString() };
        itemsArray.push(newData);
    }

    const updateObject: { [key: string]: any } = {
        [category]: itemsArray,
        updatedAt: serverTimestamp(),
    };
    updateObject[`has${category.charAt(0).toUpperCase() + category.slice(1)}`] = itemsArray.length > 0;

    await updateDoc(docRef, updateObject);
};

export const deleteCertificationOrAward = async (itemId: string, userDocId: string, category: string) => {
    const docRef = doc(firestore, "users", userDocId);
    const userDoc = await getDoc(docRef);
    if (userDoc.exists()) {
        const userData = userDoc.data();
        const itemsArray = userData[category] ? [...userData[category]] : [];
        const updatedItemsArray = itemsArray.filter(item => item.id !== itemId);

        const updateObject: { [key: string]: any } = {
            [category]: updatedItemsArray,
            updatedAt: serverTimestamp(),
        };
        updateObject[`has${category.charAt(0).toUpperCase() + category.slice(1)}`] = updatedItemsArray.length > 0;

        await updateDoc(docRef, updateObject);
    }
};

export const saveHistoryData = async (data: any, userDocId: string) => {
    await addDoc(collection(firestore, "users", userDocId, "history"), {
        ...data,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
    });
};

export const updateHistoryData = async (
    data: any,
    userDocId: string,
    historyDocId: string
) => {
    const validData = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== undefined)
    );
    const historyDocRef = doc(firestore, 'users', userDocId, 'history', historyDocId);
    await updateDoc(historyDocRef, {
        ...validData,
        updatedAt: serverTimestamp(),
    });
};

export const deleteHistory = async (
    userDocId: string,
    historyDocId: string
) => {
    const docRef = doc(firestore, 'users', userDocId, 'history', historyDocId);
    await updateDoc(docRef, {
        isDisabled: true,
        updatedAt: serverTimestamp(),
    });
};
