import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    Button,
    Container,
    Divider,
    FormControlLabel,
    IconButton,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useSnackbar } from '../../Context/SnackbarContext';
import { useCompanyData } from '../../Hooks/useCompanyData';
import { updateCompanyChatPermission, updateTeacherReadChatPermission } from '../../services/firebase/companyService';
import { roleDisplayNames } from '../../utils/options';

import AuthIdDialog from '../../components/Organisms/AuthIdDialog';
import CompanyTopNavigationContent from '../../components/Organisms/CompanyTopNavigationContent';
import Footer from '../../components/Organisms/Footer';
import NavigationBar from '../../components/Organisms/NavigationBar';
import UserRow from '../../components/Organisms/UserRow';
import { useAuthId } from '../../Hooks/useAuthId';

const Dashboard: React.FC = () => {
    const [openDialog, setOpenDialog] = useState(false);

    const { id: companyId } = useParams<{ id: string }>();
    const { showSnackbar } = useSnackbar();

    const {
        companyInfo,
        authIds,
        companyUsers: users,
        isEnabled,
        setIsEnabled,
        isTeacherReadEnabled,
        setIsTeacherReadEnabled
    } = useCompanyData(companyId!);

    const {
        handleDeleteAuthId
    } = useAuthId(companyId!);

    // authIdを論理削除する関数
    const handleDelete = async (authId: string) => {
        await handleDeleteAuthId(authId,
            () => {
                showSnackbar({ message: "認証IDを削除しました", type: 'success', title: '削除成功' });
            },
            (error) => {
                console.error("Error adding document: ", error);
            }
        );
    };

    const handleToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        setIsEnabled(newValue);
        await updateCompanyChatPermission(companyId!, newValue);
    };

    const handleTeacherReadToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        setIsTeacherReadEnabled(newValue);
        await updateTeacherReadChatPermission(companyId!, newValue);
    };

    return (
        <>
            <NavigationBar />
            <Container maxWidth="xl" sx={{ mt: 15, mb: 15 }}>
                <AuthIdDialog
                    companyId={companyId!}
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                />
                <CompanyTopNavigationContent company={companyInfo} />

                {/* ダイレクトチャットを受け入れるかどうかのボタン */}
                <Paper elevation={0} sx={{ mt: 2, width: '100%', p: 2, border: 1, borderColor: '#ECEEF1', borderRadius: '8px' }}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Box display='flex' alignItems='center'>
                            <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                                ダイレクトチャット
                            </Typography>
                            <Tooltip title="直接のチャットを許可するかどうか選択することができます">
                                <IconButton sx={{ ml: 1 }}>
                                    <HelpOutlineIcon fontSize='small'/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <FormControlLabel
                            control={<Switch checked={isEnabled} onChange={handleToggle} />}
                            label={isEnabled ? '許可する' : '許可しない'}
                        />
                    </Box>
                </Paper>

                <Paper elevation={0} sx={{ mt: 2, width: '100%', p: 2, border: 1, borderColor: '#ECEEF1', borderRadius: '8px' }}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Box display='flex' alignItems='center'>
                            <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                                チャットの閲覧許可
                            </Typography>
                            <Tooltip title="高校生だった場合、御社とのチャットを所属高校の教員が閲覧することを許可するかどうか選択することができます">
                                <IconButton sx={{ ml: 1 }} >
                                    <HelpOutlineIcon fontSize='small'/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <FormControlLabel
                            control={<Switch checked={isTeacherReadEnabled} onChange={handleTeacherReadToggle} />}
                            label={isTeacherReadEnabled ? '許可する' : '許可しない'}
                        />
                    </Box>
                </Paper>

                {/* 認証ID一覧 */}
                <Paper elevation={0} sx={{ mt: 2, width: '100%', border: 1, borderColor: '#ECEEF1', borderRadius: '8px' }}>
                    <Box display={'flex'} justifyContent={'space-between'} sx={{ mb: 2, p: 2, }}>
                        <Box>
                            <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>認証ID一覧</Typography>
                            <Typography variant="body2" component="p" sx={{ color: 'text.secondary' }}>
                                Cielへ新たなユーザーを追加するための認証IDを管理します。ここでの認証IDは、ユーザーがアカウントを作成する際に使用されます。
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Button
                                startIcon={<AddCircleOutlineIcon />}
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() => setOpenDialog(true)}>
                                ユーザー追加
                            </Button>
                        </Box>
                    </Box>
                    <Divider />
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>認証ID</TableCell>
                                    <TableCell align="left">役割</TableCell>
                                    <TableCell align="center">利用回数</TableCell>
                                    <TableCell align="center">削除</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {authIds.map((authId) => (
                                    <TableRow
                                        key={authId.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {authId.authId}
                                        </TableCell>
                                        <TableCell align="left">{roleDisplayNames[authId.role]}</TableCell>
                                        <TableCell align="center">{authId.usedCount}</TableCell>
                                        <TableCell align="center">
                                            <IconButton onClick={() => handleDelete(authId.id)} color='error'>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                {/* ユーザー一覧 */}
                <Paper elevation={0} sx={{ mt: 2, width: '100%', border: 1, borderColor: '#ECEEF1', borderRadius: '8px' }}>
                    <Box sx={{ mb: 2, p: 2, }}>
                        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>ユーザー一覧</Typography>
                        <Typography variant="body2" component="p" sx={{ color: 'text.secondary' }}>
                            {companyInfo?.name}に登録されているユーザーの一覧です。ユーザーの削除はそれぞれの画面からしか行えません。
                        </Typography>
                    </Box>
                    <Divider />
                    <Box mb={6}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left">苗字</TableCell>
                                        <TableCell align='left'>名前</TableCell>
                                        <TableCell align="center">役割</TableCell>
                                        <TableCell align="left">メールアドレス</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user) => (
                                        <UserRow key={user.id} row={user} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
            </Container>
            <Footer />
        </>
    );
}

export default Dashboard;
