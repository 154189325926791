import React from 'react';
import {
    Grid,
    Box,
    Stack,
    Alert,
    AlertTitle,
    Avatar,
    Typography,
    IconButton,
    AppBar,
    Toolbar,
    CircularProgress,
    Menu,
    MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import { QuestionSuggestions, MessageInputField } from '../Molecules/MessageInputField';
import Message from '../Molecules/Message';

interface SmChatAppBarProps {
    activeChatRoom: {
        title: string;
        companyId: string;
        avatarSrc?: string;
        startUid: string;
    } | null;
    markChatAsDeleted: () => void;
    user: User;
}

interface Timestamp {
    seconds: number;
    nanoseconds?: number;
}

interface MessageProps {
    id: string;
    text: string;
    senderId: string;
    createdAt: {
        seconds: number;
        nanoseconds?: number;
    };
    readReceipts: { [key: string]: Timestamp };
}

interface User {
    uid: string;
    userType: string;
}

interface UserIcon {
    uid: string;
    userIconUrl: string;
}

interface SmChatComponentProps {
    messages: MessageProps[];
    user: User;
    isLoadingAiResponse: boolean;
    scrollRef: React.RefObject<HTMLDivElement>;
    showArchivedChats: boolean;
    sendMessage: (message: string) => void;
    markChatAsDeleted: () => void;
    userIcons: UserIcon[];
}

export const SmChatComponent: React.FC<SmChatComponentProps> = ({
    messages,
    user,
    isLoadingAiResponse,
    scrollRef,
    showArchivedChats,
    sendMessage,
    userIcons,
}) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 147px)' }} id="back-to-top-anchor">
            <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2, WebkitOverflowScrolling: 'touch' }}>
                <Stack spacing={2}>
                    {messages?.map((message) => (
                        <Message key={message.id} message={message} user={user} userIcons={userIcons} />
                    ))}
                    {isLoadingAiResponse && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {/* スクロールのためのダミー要素 */}
                    <div ref={scrollRef} />
                </Stack>
            </Box>

            {/* 送信バー */}
            {!showArchivedChats && messages.length === 0 && (
                <QuestionSuggestions onSend={sendMessage} />
            )}
            {!showArchivedChats ? (
                <Box sx={{ px: 1, pt: 0.5, display: 'flex', alignItems: 'center', mt: 'auto', width: '100%' }}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={true}>
                            <MessageInputField onSend={sendMessage} />
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Box sx={{ p: 1, display: 'flex', alignItems: 'center', mt: 'auto', width: '100%', justifyContent: 'center' }}>
                    <Alert variant="filled" severity="error">
                        <AlertTitle>アーカイブされたチャットルームです</AlertTitle>
                    </Alert>
                </Box>
            )}
        </Box>
    );
};

export const SmChatAppBar: React.FC<SmChatAppBarProps> = ({ activeChatRoom, markChatAsDeleted, user }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // バックボタンのクリックハンドラー
    const handleBackClick = () => {
        navigate('/chat/null');
    };

    // アバターのクリックハンドラー
    const handleAvatarClick = () => {
        if (activeChatRoom && user?.userType !== 'company') {
            window.open(`/company/${activeChatRoom.companyId}/home`, '_blank');
        } else {
            if (activeChatRoom) {
                window.open(`/profile/${activeChatRoom.startUid}`, '_blank');
            }
        }
    };

    // メニューのオープンハンドラー
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // メニューのクローズハンドラー
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // チャット削除ハンドラー
    const handleDeleteChat = () => {
        handleMenuClose();
        markChatAsDeleted();
    };

    return (
        <AppBar component="nav"
            color="inherit"
            elevation={0}
            sx={{
                borderBottom: '0.5px solid',
                borderColor: 'grey.400',
            }}>
            <Toolbar variant="dense">
                <IconButton edge="start" color="inherit" aria-label="back" onClick={handleBackClick}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography
                    variant="body1"
                    component="div"
                    sx={{
                        flexGrow: 1,
                        textAlign: 'center',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {activeChatRoom?.title}
                </Typography>
                <Avatar
                    src={activeChatRoom?.avatarSrc}
                    alt=""
                    onClick={handleAvatarClick}
                >
                    {activeChatRoom?.avatarSrc ? '' : activeChatRoom?.title.charAt(0)}
                </Avatar>
                <IconButton edge="end" color="inherit" aria-label="more" onClick={handleMenuOpen}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MenuItem onClick={handleDeleteChat}>削除</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};
