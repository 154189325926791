import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { getUserIcons, markAllMessagesAsRead, markMessageAsRead } from '../services/firebase/messageService';

interface Timestamp {
    seconds: number;
    nanoseconds?: number;
}

interface Message {
    id: string;
    senderId: string;
    text: string;
    timestamps: any;
    createdAt: any;
    updatedAt: any;
    readReceipts: { [key: string]: Timestamp };
}

interface UserIcon {
    uid: string;
    userIconUrl: string;
}

export const useMessages = (activeChat: string, currentUserId: string) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [userIcons, setUserIcons] = useState<UserIcon[]>([]);

    useEffect(() => {
        if (!activeChat) return;
        // メッセージの取得
        // const q = getMessagesQuery(activeChat);
        // console.log('activeChat', activeChat)
        const q = query(collection(firestore, `chats/${activeChat}/messages`), orderBy('timestamps'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const msgs = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            } as Message));

            setMessages(msgs);
        });

        // ユーザーアイコンの取得
        const fetchUserIcons = async () => {
            const icons = await getUserIcons(activeChat);
            setUserIcons(icons);
        };

        fetchUserIcons();

        return () => unsubscribe();
    }, [activeChat, currentUserId]);

    const markAsRead = (messageId: string, isAlreadyRead: boolean) => {
        if (!isAlreadyRead) {
            markMessageAsRead(activeChat, messageId, currentUserId);
        }
    };

    const markAllAsRead = async () => {
        await markAllMessagesAsRead(activeChat, currentUserId);
    };

    return { messages, userIcons, markAsRead, markAllAsRead };
};
