// React core
import React from 'react';
import { useParams } from 'react-router-dom';

// MUI components
import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    useTheme,
    useMediaQuery,
    Chip,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    IconButton,
    FormControlLabel,
    Switch,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// Firebase
import { auth } from '../firebase.js';

// Context hooks
import { useUser } from '../Context/UserContext.js';
import { useSnackbar } from '../Context/SnackbarContext.js';

// Local components
import ResponsiveContainer from '../components/Templates/ResponsiveContainer';
import NavigationBar from '../components/Organisms/NavigationBar';
import Footer from '../components/Organisms/Footer';
import ProfileTopNavigationContent from '../components/Organisms/ProfileTopNavigationContent';

import LabelledInput from '../components/Profile/LabelledInput.js';
import UpdateEmailDialog from '../components/Profile/UpdateEmailDialog.js';
import UpdatePasswordDialog from '../components/Profile/UpdatePasswordDialog.js';
import AddEmailPasswordDialog from '../components/Auth/AddEmailPasswordDialog.js';

// Utils and data
import {
    ASPIRATIONS_OPTIONS,
    SCHOOL_OPTIONS,
    GRADE_OPTIONS,
    GENDER_OPTIONS,
    INTERESTING_WORK_CATEGORY_OPTIONS,
    INTERESTING_LEARN_CATEGORY_OPTIONS,
    EMAIL_NOTIFICATION_OPTIONS,
} from '../utils/options';
import { getLabelByValue } from '../utils/helpers/getLabelByValue.js';

import { useUserData, useProfileFormData, useDialogs, useFormHandlers, initialFormData } from '../Hooks/useProfileEdit';
import { updateUserInFirestore } from '../services/firebase/userService';

import { cleanFormData, checkLanguagesChanged } from '../utils/formHelpers';

interface Language {
    lang: string;
    level: string;
}

const LanguageSelect = ({ language, onLanguageChange }: { language: Language; onLanguageChange: (field: keyof Language, value: string) => void }) => {
    return (
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <FormControl fullWidth>
                <InputLabel>言語</InputLabel>
                <Select label="言語" value={language.lang} onChange={(e) => onLanguageChange('lang', e.target.value)}>
                    <MenuItem value="日本語">日本語</MenuItem>
                    <MenuItem value="英語">英語</MenuItem>
                    {/* 他の言語 */}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 150 }}>
                <InputLabel>レベル</InputLabel>
                <Select label="レベル" value={language.level} onChange={(e) => onLanguageChange('level', e.target.value)}>
                    <MenuItem value="勉強中">勉強中</MenuItem>
                    <MenuItem value="日常会話">日常会話</MenuItem>
                    <MenuItem value="ビジネス会話">ビジネス会話</MenuItem>
                    <MenuItem value="ネイティブ">ネイティブ</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
};

const ProfileEdit = () => {

    // ユーザー情報を取得
    const { id } = useParams<{ id: string }>();
    const { displayUser, user } = useUserData(id!);
    const isUser = user?.uid === id;
    const { user: currentUser } = useUser()

    const [isTeacherReadEnabled, setIsTeacherReadEnabled] = React.useState<boolean>(currentUser?.allowsTeacherRead ?? false);
    const { formData, setFormData } = useProfileFormData(id, user);
    const { dialogsOpen, toggleModal } = useDialogs();
    const {
        handleInputChange,
        handleAutocompleteChange,
        handleLanguageChange,
        addLanguageField,
    } = useFormHandlers(formData, setFormData);
    const { showSnackbar } = useSnackbar();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));

    // Container maxWidth="md" の幅
    const maxWidthMD = theme.breakpoints.values.md; // 960px がデフォルト値
    const containerPadding = theme.spacing(3); // Container の左右のパディングを考慮

    // ビューポートが md サイズ以上の場合の右マージンを計算
    const rightMargin = matchesMD
        ? `calc((100vw - ${maxWidthMD}px) / 2 + ${containerPadding})`
        : `${theme.spacing(2)}`; // md サイズ未満の場合は 16px（theme.spacing(2)）

    // 変更があるかどうかをチェックする関数
    const hasChanges = () => {
        // 言語以外の部分の変更をチェック
        const nonLanguageChanges = JSON.stringify({ ...formData, languages: [] }) !== JSON.stringify({ ...initialFormData(user), languages: [] });
        // 言語の部分の変更をチェック
        const languageChanges = checkLanguagesChanged(initialFormData(user)?.languages, formData.languages);
        return nonLanguageChanges || languageChanges; // どちらか一方がtrueなら変更があったとみなす
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!user) return;
        const cleanedFormData = cleanFormData(formData);
        try {
            await updateUserInFirestore(user.uid, cleanedFormData); // 既存の更新関数を使用
            showSnackbar({ message: 'プロファイル情報を更新しました', type: 'success' });
        } catch (error) {
            showSnackbar({ message: 'プロファイル情報の更新に失敗しました', type: 'error' });
        }
    };

    const handleTeacherReadToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        setIsTeacherReadEnabled(newValue);
        if (currentUser && currentUser.uid) {
            await updateUserInFirestore(currentUser.uid, { allowsTeacherRead: newValue });
        }
    };


    return (
        <>
            <NavigationBar />
            <ProfileTopNavigationContent user={displayUser} />
            <ResponsiveContainer>
                <Paper elevation={0} sx={{ mb: 4, width: '100%', p: 2, border: 1, borderColor: '#ECEEF1', borderRadius: '8px' }}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Box display='flex' alignItems='center'>
                            <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                                チャットの閲覧許可
                            </Typography>
                            <Tooltip title="チャットを学校の先生に確認してもらうことができます">
                                <IconButton sx={{ ml: 1 }} >
                                    <HelpOutlineIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <FormControlLabel
                            control={<Switch
                                checked={isTeacherReadEnabled}
                                onChange={handleTeacherReadToggle}
                            />}
                            label={
                                isTeacherReadEnabled ? '許可する' : '許可しない'
                            }
                        />
                    </Box>
                </Paper>
                <Box>
                    <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold', mb: 4 }}>今後の予定</Typography>
                    {/* 以下の部分で，興味のある分野を選択できるようにしてください．また，それぞれの選択肢もいくつか生成してください． 
                    進学と勉強2つの分野においてグループを作成してください*/}
                    {isUser ? (
                        <LabelledInput
                            label="今後の予定"
                            title={"今後の予定"}
                            value={formData.aspirations}
                            onChange={(newValue: any) => handleInputChange('aspirations', newValue)}
                            options={ASPIRATIONS_OPTIONS}
                        />
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                今後の予定
                            </Typography>
                            <Typography variant="body1" component="label" color="text.primary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                {getLabelByValue(ASPIRATIONS_OPTIONS, displayUser?.aspirations)}
                            </Typography>
                        </Box>
                    )}

                    {/* 働きたい分野 */}
                    {isUser ? (
                        <LabelledInput
                            label="働きたい分野"
                            title="働きたい分野"
                            value={formData?.interestsEmploymentTags?.map((tag: string) =>
                                INTERESTING_WORK_CATEGORY_OPTIONS.find(option => option.value === tag) || tag
                            )}
                            onChange={(newValue: any) => handleAutocompleteChange('interestsEmploymentTags', newValue)}
                            options={{
                                autocomplete: INTERESTING_WORK_CATEGORY_OPTIONS,
                                formLabel: '働きたい分野'
                            }}
                        />
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                働きたい分野
                            </Typography>
                            <Typography variant="body1" component="label" color="text.primary" sx={{
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                <Stack direction="row" spacing={1}>
                                    {displayUser?.interestsEmploymentTags?.map((label: string, index: number) => (
                                        <Chip color="primary" key={index} label={getLabelByValue(INTERESTING_WORK_CATEGORY_OPTIONS, label)} />
                                    ))}
                                </Stack>
                            </Typography>
                        </Box>
                    )}

                    {/*　コメ学びたい分野 */}
                    {isUser ? (
                        <LabelledInput
                            label={isUser && user.status !== 'highSchoolStudent' ? "仕事を通して学びたい分野" : "働きたい分野・学びたい分野"}
                            title={isUser && user.status !== 'highSchoolStudent' ? "仕事を通して学びたい分野" : "働きたい分野・学びたい分野"}
                            value={formData?.interestsEducationTags?.map((tag: string) =>
                                INTERESTING_LEARN_CATEGORY_OPTIONS.find(option => option.value === tag) || tag
                            )}
                            onChange={(newValue: any) => handleAutocompleteChange('interestsEducationTags', newValue)}
                            options={{
                                autocomplete: INTERESTING_LEARN_CATEGORY_OPTIONS,
                                formLabel: isUser && user.status !== 'highSchoolStudent' ? "仕事を通して学びたい分野" : "働きたい分野・学びたい分野"
                            }}
                        />
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                {isUser && user.status !== 'highSchoolStudent' ? "仕事を通して学びたい分野" : "働きたい分野・学びたい分野"}
                            </Typography>
                            <Typography variant="body1" component="label" color="text.primary" sx={{
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                <Stack direction="row" spacing={1}>
                                    {displayUser?.interestsEducationTags?.map((label: string, index: number) => (
                                        <Chip color="primary" key={index} label={getLabelByValue(INTERESTING_LEARN_CATEGORY_OPTIONS, label)} />
                                    ))}
                                </Stack>
                            </Typography>
                        </Box>
                    )}

                    {/* メールの通知設定 */}
                    {isUser ? (
                        <LabelledInput
                            label="メール通知設定"
                            title={"メール通知設定"}
                            value={formData.emailNotification}
                            onChange={(newValue: any) => handleInputChange('emailNotification', newValue)}
                            options={EMAIL_NOTIFICATION_OPTIONS}
                        />
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                メール通知設定
                            </Typography>
                            <Typography variant="body1" component="label" color="text.primary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                {getLabelByValue(EMAIL_NOTIFICATION_OPTIONS, displayUser?.emailNotification)}
                            </Typography>
                        </Box>
                    )}
                </Box>

                <Box sx={{ mt: 10 }}>
                    <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold', mb: 4 }}>基本情報</Typography>

                    {/* 氏名 */}
                    {isUser && (
                        <>
                            <Box sx={{ gap: 4 }}>
                                <LabelledInput
                                    label="苗字"
                                    value={formData.lastName}
                                    onChange={(newValue: string) => handleInputChange('lastName', newValue)}
                                    name="lastName"
                                    title={"氏名"}
                                    options={undefined}
                                />
                                <LabelledInput
                                    label="名前"
                                    value={formData.firstName}
                                    onChange={(newValue: string) => handleInputChange('firstName', newValue)}
                                    name="fistName" title={undefined} options={undefined} />
                            </Box>
                        </>
                    )}

                    {/* 性別 */}
                    {isUser ? (
                        <LabelledInput label="性別" value={formData.gender} title={"性別"} onChange={(newValue: string) => handleInputChange('gender', newValue)} options={GENDER_OPTIONS} />
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <Typography variant="body1" component="label" color="text.secondary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                性別
                            </Typography>
                            <Typography variant="body1" component="label" color="text.primary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                {getLabelByValue(GENDER_OPTIONS, displayUser?.gender)}
                            </Typography>
                        </Box>
                    )}

                    {/* 高校名 */}
                    {isUser && user?.userType === 'highSchool' ? (
                        <LabelledInput
                            label="高校名"
                            title={"高校名"}
                            value={formData.schoolName}
                            onChange={(newValue: string) => handleInputChange('schoolName', newValue)}
                            options={SCHOOL_OPTIONS}
                            disabled={false}
                        />
                    ) : (
                        displayUser?.userType === 'highSchool' && (
                            <Box sx={{
                                flexDirection: { xs: 'column', md: 'row' },
                                alignItems: { md: 'center' },
                                display: 'flex',
                                gap: 0.5,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                    fontWeight: 'bold',
                                    width: { md: 'auto' },
                                    minWidth: { md: '160px' },
                                    marginBottom: { xs: 0, md: 0 },
                                }}>
                                    高校名
                                </Typography>
                                <Typography variant="body1" component="label" color="text.primary" sx={{
                                    fontWeight: 'bold',
                                    width: { md: 'auto' },
                                    minWidth: { md: '160px' },
                                    marginBottom: { xs: 0, md: 0 },
                                }}>
                                    {getLabelByValue(SCHOOL_OPTIONS, displayUser?.schoolName)}
                                </Typography>
                            </Box>
                        )
                    )}

                    {/* 学年 */}
                    {isUser && user?.userType === 'highSchool' ? (
                        <LabelledInput
                            label="学年"
                            title={"学年"}
                            value={formData.grade}
                            onChange={(newValue: string) => handleInputChange('grade', newValue)}
                            options={GRADE_OPTIONS}
                            disabled={false}
                        />
                    ) : (
                        displayUser?.userType === 'highSchool' && (
                            <Box sx={{
                                flexDirection: { xs: 'column', md: 'row' },
                                alignItems: { md: 'center' },
                                display: 'flex',
                                gap: 0.5,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                    fontWeight: 'bold',
                                    width: { md: 'auto' },
                                    minWidth: { md: '160px' },
                                    marginBottom: { xs: 0, md: 0 },
                                }}>
                                    学年
                                </Typography>
                                <Typography variant="body1" component="label" color="text.primary" sx={{
                                    fontWeight: 'bold',
                                    width: { md: 'auto' },
                                    minWidth: { md: '160px' },
                                    marginBottom: { xs: 0, md: 0 },
                                }}>
                                    {getLabelByValue(GRADE_OPTIONS, displayUser?.grade)}
                                </Typography>
                            </Box>
                        )
                    )}

                    {isUser && (
                        <>
                            <LabelledInput
                                label="電話番号"
                                value={formData.phoneNumber}
                                onChange={(newValue: string) => handleInputChange('phoneNumber', newValue)}
                                name="phoneNumber"
                                title={"電話番号"} options={undefined} />
                            <LabelledInput
                                label={!formData.emailAddresses[0].email ? "メールアドレスが登録されていません" : "メールアドレス（ログイン用）"}
                                value={formData.emailAddresses[0].email || "メールアドレスが登録されていません"}
                                type="email"
                                name="email"
                                title="メールアドレス"
                                disabled={true}
                                options={undefined} onChange={undefined} />
                            <LabelledInput
                                label="メールアドレス2（サブ）"
                                value={formData.emailAddresses[1]?.email || ""}
                                onChange={(newValue: string) => handleInputChange('emailAddresses', newValue, 1)}
                                type="email"
                                name="email2"
                                isTitle={true}
                                options={undefined} title={undefined} />
                            <LabelledInput
                                label="メールアドレス3（サブ）"
                                value={formData.emailAddresses[2]?.email || ""}
                                onChange={(newValue: string) => handleInputChange('emailAddresses', newValue, 2)}
                                // onChange={(newValue) => handleInputChange('email3', newValue)}
                                type="email"
                                name="email3"
                                isTitle={true}
                                options={undefined} title={undefined} />


                            {/* userでプロバイダーがpasswordの時はこっち，LINEの時はemail/passwordを追加できるダイアログを追加する */}
                            {auth && auth?.currentUser?.providerData && auth?.currentUser?.providerData.length > 0 && auth?.currentUser?.providerData[0].providerId === 'oidc.line' && auth?.currentUser.email ? (
                                <Box sx={{
                                    flexDirection: { xs: 'column', md: 'row' },
                                    justifyContent: 'flex-end',
                                    alignItems: { md: 'center' },
                                    display: 'flex',
                                    gap: 0.5,
                                    marginBottom: 4,
                                }}>
                                    <Box component="label" color="text.secondary" sx={{
                                        width: { md: 'auto' },
                                        minWidth: { md: '160px' },
                                        marginBottom: { xs: 0, md: 0 },
                                    }}>
                                    </Box>
                                    <Box sx={{
                                        flexDirection: { xs: 'row', md: 'row' },
                                        alignItems: { md: 'center' },
                                        display: 'flex',
                                        gap: 2,
                                        justifyContent: 'flex-end',
                                    }}>
                                        <Button variant="outlined" onClick={() => toggleModal('updatePassword', true)}>
                                            パスワードを更新
                                        </Button>
                                        {/* メール更新モーダルを開くボタン */}
                                        <Button variant="outlined" onClick={() => toggleModal('updateEmail', true)}>
                                            メインアドレスを更新
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{
                                    flexDirection: { xs: 'column', md: 'row' },
                                    justifyContent: 'flex-end',
                                    alignItems: { md: 'center' },
                                    display: 'flex',
                                    gap: 0.5,
                                    marginBottom: 4,
                                }}>
                                    <Box component="label" color="text.secondary" sx={{
                                        width: { md: 'auto' },
                                        minWidth: { md: '160px' },
                                        marginBottom: { xs: 0, md: 0 },
                                    }}>
                                    </Box>
                                    <Box sx={{
                                        flexDirection: { xs: 'row', md: 'row' },
                                        alignItems: { md: 'center' },
                                        display: 'flex',
                                        gap: 2,
                                        justifyContent: 'flex-end',
                                    }}>
                                        {/* メール追加モーダルを開くボタン */}
                                        <Button variant="outlined" onClick={() => toggleModal('addEmail', true)}>
                                            アドレス認証を追加
                                        </Button>
                                    </Box>
                                </Box>
                            )}


                            {/* 住所に関するフォーム */}
                            <LabelledInput label="郵便番号" value={formData.postalCode} onChange={(newValue: string) => handleInputChange('postalCode', newValue)} type="" name="郵便番号" title="住所" isTitle={true} options={undefined} />
                            <LabelledInput label="都道府県" value={formData.prefecture} onChange={(newValue: string) => handleInputChange('prefecture', newValue)} type="" name="都道府県" isTitle={true} options={undefined} title={undefined} />
                            <LabelledInput label="市町村" value={formData.city} onChange={(newValue: string) => handleInputChange('city', newValue)} type="" name="市町村" isTitle={true} options={undefined} title={undefined} />
                            <LabelledInput label="番地以降" value={formData.streetAddress} onChange={(newValue: string) => handleInputChange('streetAddress', newValue)} type="" name="番地以降" isTitle={true} options={undefined} title={undefined} />
                        </>
                    )}
                </Box>

                <Box sx={{ mt: 10 }}>
                    <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold', mb: 4 }}>言語</Typography>
                    {isUser ? (
                        formData?.languages?.map((language: Language, index: number) => (
                            <LanguageSelect
                                key={index}
                                language={language}
                                onLanguageChange={(field, value) => handleLanguageChange(index, field, value)}
                            />
                        ))
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ maxWidth: 'md' }} aria-label="languages table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>言語</TableCell>
                                            <TableCell align="right">レベル</TableCell>
                                            {/* 必要に応じて他のヘッダーセルを追加 */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {displayUser?.languages?.map((language: Language, index: number) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {language.lang}
                                                </TableCell>
                                                <TableCell align="right">{language.level}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
                    {isUser && (
                        <Button onClick={addLanguageField} variant="contained">言語を追加</Button>
                    )}
                </Box>

                {isUser && (
                    <Box sx={{
                        position: 'fixed',
                        right: rightMargin,
                        bottom: theme.spacing(3), // 20px
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 4
                    }}>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            disabled={!hasChanges()}
                        >
                            保存
                        </Button>
                    </Box>
                )}

                {/* パスワード更新モーダル */}
                <UpdatePasswordDialog
                    open={dialogsOpen.updatePassword}
                    handleClose={() => toggleModal('updatePassword', false)}
                />

                {/* メールアドレス更新モーダル */}
                <UpdateEmailDialog
                    open={dialogsOpen.updateEmail}
                    handleClose={() => toggleModal('updateEmail', false)}
                />

                {/* メールアドレス追加モーダル */}
                <AddEmailPasswordDialog
                    open={dialogsOpen.addEmail}
                    handleClose={() => toggleModal('addEmail', false)}
                />


            </ResponsiveContainer>
            <Footer />
        </>
    );
}

export default ProfileEdit;
