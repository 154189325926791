// React core
import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI components
import {
    Avatar,
    Alert,
    AlertTitle,
    Box,
    CssBaseline,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    List,
    Stack,
    Typography,
    Toolbar,
} from '@mui/material';

// MUI icons
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import BusinessIcon from '@mui/icons-material/Business';
import RepartitionIcon from '@mui/icons-material/Repartition';

// Local components
import Message from '../Molecules/Message';
import CustomListItem from '../Molecules/CustomListItem';
import { QuestionSuggestions, MessageInputField } from '../Molecules/MessageInputField';
import NavigationBar from '../Organisms/NavigationBar';

interface ChatRoom {
    id: string;
    title: string;
    detail: string;
    participants: string[];
    avatarSrc: string;
    companyId: string;
    chatTag: string;
    lastMessage: string;
    companyDisplaySrc: string;
    startUid: string;
    // その他必要なプロパティ
}

interface User {
    uid: string;
    userType: string;
}

interface PcChatComponentProps {
    showArchivedChats: boolean;
    toggleChats: () => void;
    chatRooms: ChatRoom[];
    activeChat: string | null;
    setActiveChat: (chatId: string) => void;
    activeChatRoom: ChatRoom;
    user: User;
    markChatAsDeleted: () => void;
    messages: any[];
    isLoadingAiResponse: boolean;
    scrollRef: React.RefObject<HTMLDivElement>;
    sendMessage: (message: string) => void;
    userIcons: { uid: string; userIconUrl: string }[];
    isRoomListLoading: boolean;
}

export const PcChatComponent: React.FC<PcChatComponentProps> = ({
    showArchivedChats,
    toggleChats,
    chatRooms,
    activeChat,
    setActiveChat,
    activeChatRoom,
    user,
    markChatAsDeleted,
    messages,
    isLoadingAiResponse,
    scrollRef,
    sendMessage,
    userIcons,
    isRoomListLoading
}) => {

    const navigate = useNavigate()
    const showCompanyLink = user.userType !== 'company' && activeChatRoom?.companyId;
    return (
        <>
            <CssBaseline />
            <NavigationBar />
            <Toolbar />
            <Box>
                <Grid container spacing={2}>
                    {/* 左側：チャットルーム一覧 */}
                    <Grid item xs={12} sm={3} style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}>
                        <Box sx={{ position: 'relative' }}>
                            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>チャットルーム</Typography>
                                <IconButton aria-label="archive" onClick={toggleChats} sx={{ position: 'absolute', right: 0 }}>
                                    {showArchivedChats ? (
                                        <RepartitionIcon fontSize="large" />
                                    ) : (
                                        <ArchiveIcon fontSize="large" />
                                    )}
                                </IconButton>
                            </Box>
                            {/* チャットルーム一覧の表示 */}
                            <List>
                                {chatRooms.map((room) => (
                                    <CustomListItem
                                        key={room.id}
                                        avatarSrc={room.avatarSrc}
                                        title={room.title}
                                        // tag={room.chatTag}
                                        lastMessage={room.lastMessage || 'メッセージを開始してください'}
                                        isActive={activeChat === room.id}
                                        onClick={() => {
                                            setActiveChat(room.id);
                                            navigate(`/chat/${room.id}`);
                                        }}
                                        isLoading={isRoomListLoading}
                                    />
                                ))}
                            </List>
                        </Box>
                    </Grid>

                    {/* 右側：チャットルームの内容 */}
                    <Grid item xs={12} sm={9} style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}>
                        {activeChatRoom ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar
                                            sx={{ mr: 3, width: 56, height: 56, cursor: 'pointer' }}
                                            aria-label="recipe"
                                            src={activeChatRoom.avatarSrc}
                                            onClick={() => {
                                                if (activeChatRoom && user?.userType !== 'company') {
                                                    window.open(`/company/${activeChatRoom.companyId}/home`, '_blank');
                                                } else {
                                                    if (activeChatRoom) {
                                                        window.open(`/profile/${activeChatRoom.startUid}`, '_blank');
                                                    }
                                                }
                                            }}
                                        >
                                            {activeChatRoom.title.charAt(0)}
                                        </Avatar>
                                        <Box>
                                            <Typography variant="subtitle2">チャットを開始しました</Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
                                                <Typography variant="h5" sx={{
                                                    fontWeight: 'bold',
                                                    mr: { sm: 3 },
                                                    mb: { xs: 1, sm: 0 },
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis'
                                                }}>
                                                    {activeChatRoom.title}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' }, flexDirection: { xs: 'column', sm: 'row' } }}>
                                                <Typography variant="subtitle1" color='text.secondary' sx={{ fontWeight: 'bold', mr: { xs: 0, sm: 3 } }}>
                                                    {activeChatRoom.chatTag}
                                                </Typography>
                                                {showCompanyLink && (
                                                    <Typography
                                                        component="div"
                                                        variant="body2"
                                                        sx={{
                                                            mr: { xs: 0, sm: 1 },
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            textDecoration: 'none',
                                                            color: 'text.secondary',
                                                            cursor: 'pointer',
                                                            flexDirection: 'row',
                                                            '&:hover': {
                                                                color: 'primary.main',
                                                            }
                                                        }}
                                                        onClick={() => navigate(`/company/${activeChatRoom.companyId}/home`)}
                                                    >
                                                        <BusinessIcon sx={{
                                                            mr: 0.5,
                                                            fontSize: 'default'
                                                        }} />
                                                        企業ページを見る
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {!showArchivedChats && (
                                        <Box>
                                            <IconButton aria-label="delete" onClick={markChatAsDeleted}>
                                                <DeleteIcon fontSize="large" />
                                            </IconButton>
                                        </Box>
                                    )}
                                </Box>

                                <Divider />

                                {/* チャットメッセージのリスト */}
                                <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
                                    <Stack spacing={2}>
                                        {messages.map((message) => (
                                            <Message key={message.id} message={message} user={user} userIcons={userIcons} />
                                        ))}
                                        {isLoadingAiResponse && (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                                <CircularProgress />
                                            </Box>
                                        )}
                                        {/* スクロールのためのダミー要素 */}
                                        <div ref={scrollRef} />
                                    </Stack>
                                </Box>

                                {/* アーカイブされていなくてメッセージが何もない時に質問の最初の一手を提案するボタン */}
                                {!showArchivedChats && messages.length === 0 && (
                                    <QuestionSuggestions onSend={sendMessage} />
                                )}
                                {/* 送信バー */}
                                {!showArchivedChats ? (
                                    <Box sx={{ px: 1, pt: 0.5, display: 'flex', alignItems: 'center', mt: 'auto', width: '100%' }}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={true}>
                                                <MessageInputField onSend={sendMessage}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ) : (
                                    <Box sx={{ p: 1, display: 'flex', alignItems: 'center', mt: 'auto', width: '100%', justifyContent: 'center' }}>
                                        <Alert variant="filled" severity="error">
                                            <AlertTitle>アーカイブされたチャットルームです</AlertTitle>
                                        </Alert>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pb: 10 }}>
                                <Typography variant="h5">チャットルームを選択してください</Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}