import React from 'react';
import { Box, Typography, Avatar, Paper } from '@mui/material';

interface Timestamp {
    seconds: number;
    nanoseconds?: number;
}

interface MessageProps {
    message: {
        id: string;
        senderId: string;
        text: string;
        createdAt: Timestamp;
        readReceipts: { [key: string]: Timestamp };
    };
    user: {
        uid: string;
    };
    userIcons: { uid: string; userIconUrl: string }[];
}

const formatTimestamp = (timestamp: Timestamp) => {
    if (!timestamp?.seconds) return '';
    // Timestampのsecondsをミリ秒に変換してDateオブジェクトを生成
    const date = new Date(timestamp.seconds * 1000);

    // 年月日時分秒を取得
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月は0から始まるので+1
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    // yyyy/mm/dd hh:mm:ss形式でフォーマット
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

const Message: React.FC<MessageProps> = ({ message, user, userIcons }) => {
    const isCurrentUser = message.senderId === user.uid;
    const senderAvatar = userIcons?.find(icon => icon?.uid === message?.senderId)?.userIconUrl || 'null';

    return (
        <Box sx={{ display: 'flex', justifyContent: isCurrentUser ? 'flex-end' : 'flex-start' }}>
            {!isCurrentUser && (
                <Avatar alt="User Avatar" src={senderAvatar} sx={{ mr: 2 }}>
                    {senderAvatar ? 'AI' : ''}
                </Avatar>
            )}
            <Box sx={{ maxWidth: { xs: '100%', sm: '70%' }, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Paper
                        elevation={0}
                        sx={{
                            bgcolor: isCurrentUser ? 'primary.main' : 'grey.200',
                            color: isCurrentUser ? 'white' : 'black',
                            borderRadius: isCurrentUser ? '10px 0px 10px 10px' : '0px 10px 10px 10px',
                            p: 1,
                            paddingX: 2,
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                            {message.text}
                        </Typography>
                    </Paper>
                    <Typography
                        variant="caption"
                        sx={{ mt: 0.5, color: 'text.secondary', alignSelf: isCurrentUser ? 'flex-end' : 'flex-start' }}
                    >
                        {formatTimestamp(message.createdAt)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default Message;
