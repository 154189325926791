import { collection, query, orderBy, doc, getDoc, updateDoc, where, getDocs, writeBatch } from 'firebase/firestore';
import { firestore } from '../../firebase';

export const getMessagesQuery = (activeChat: string) => {
    return query(collection(firestore, `chats/${activeChat}/messages`), orderBy('timestamps'));
};

export const getUserIcons = async (activeChat: string) => {
    const chatDocRef = doc(firestore, 'chats', activeChat);
    const chatDoc = await getDoc(chatDocRef);

    if (chatDoc.exists()) {
        const participantIds = chatDoc.data().participantIds;
        const userIconPromises = participantIds.map(async (uid: string) => {
            const userDocRef = doc(firestore, 'users', uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                return { uid, userIconUrl: userDoc.data().userIconUrl || 'null' };
            }
            return { uid, userIconUrl: 'default-icon-url.png' };
        });
        return await Promise.all(userIconPromises);
    }
    return [];
};

export const markMessageAsRead = async (activeChat: string, messageId: string, userId: string) => {
    const messageDocRef = doc(firestore, `chats/${activeChat}/messages`, messageId);
    await updateDoc(messageDocRef, {
        [`readReceipts.${userId}`]: new Date() // サーバータイムスタンプを使用する場合はFieldValue.serverTimestamp()を使用
    });
};

export const markAllMessagesAsRead = async (activeChat: string, userId: string) => {
    const messagesQuery = query(
        collection(firestore, `chats/${activeChat}/messages`),
        where(`readReceipts.${userId}`, '==', null)
    );
    const querySnapshot = await getDocs(messagesQuery);

    if (querySnapshot.empty) return;

    const batch = writeBatch(firestore);
    querySnapshot.forEach((doc) => {
        batch.update(doc.ref, {
            [`readReceipts.${userId}`]: new Date() // サーバータイムスタンプを使用する場合はFieldValue.serverTimestamp()を使用
        });
    });

    await batch.commit();
};
